.sectionFour {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    margin-top: 150px;
    padding-left: 15px;
    padding-right: 15px;
}

.sectionFour .sectionFourContainer {
    width: 100%;
    max-width: 895px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 140px;
}

.sectionFour .sectionFourContainer p,
.sectionFour .sectionFourContainer span {
    font-size: 24px;
    text-align: center;

    display: -webkit-box;        /* Define um contêiner flexível em bloco */
    -webkit-box-orient: vertical; /* Define a orientação do layout */
    line-clamp: 2;
    -webkit-line-clamp: 2;       /* Limita o texto a 3 linhas */
    overflow: hidden;            /* Esconde o texto extra */
    text-overflow: ellipsis;
}

.sectionFour .sectionFourContainer span {
    color: #A362FF;
}

.sectionFour .sectionFourContainer .paragrafoMin {
    font-size: 17px;
}

.sectionFour .imagemFooter {
    width: 100%;
}

.sectionFour .imagemFooter img {
    width: 100%;
}

.sectionFour .imagemFooterMobile {
    display: none;
}

@media (max-width: 810px) {
    .sectionFour {
        margin-top: 50px;
    }

    .sectionFour .sectionFourContainer {
        margin-bottom: 80px;
    }

    .sectionFour .sectionFourContainer p,
    .sectionFour .sectionFourContainer span {
        font-size: 1.4rem;
        padding: 0 15px;
    }

    .sectionFour .sectionFourContainer .paragrafoMin {
        padding: 0 15px;
    }

    .sectionFour .imagemFooterDefault {
        display: none;
    }

    .sectionFour .imagemFooterMobile {
        display: block;
    }
}

@media (max-width: 480px) {
    .sectionFour {
        margin-top: 40px;
    }

    .sectionFour .sectionFourContainer {
        margin-bottom: 50px;
    }

    .sectionFour .sectionFourContainer p,
    .sectionFour .sectionFourContainer span {
        font-size: 1.1rem;
        line-height: 30px;
    }

    .sectionFour .sectionFourContainer .paragrafoMin {
        padding: 0 15px;
        font-size: 0.9rem;
    }

    
}
