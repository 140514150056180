.sectionOne {
    display: flex;
    align-items: center;
    position: relative;
    height: 600px;
}

.arrowDeveloper {
    position: absolute;
    top: 96px;
    left: 324px;
    display: flex;
}

.arrowDeveloper span {
    color: #7127BA;
    font-weight: bold;
}

.ArrowDeveloperMobile img {
    position: absolute;
    top: 15px;
    left: 0px;
    transform: translate(-90px);
}

.arrowDeveloper p {
    font-size: 17px;
}

.sectionOne .informations {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-left: -40px;
    position: relative;
}

.sectionOne .informations p {
    font-size: 17px;
}

.sectionOne .informations .informationH2 {
    font-size: 45px;
    width: 460px;
    line-height: 50px;
    margin: 0px 0 10px;
}

.sectionOne .informations .informationH2 span {
    color: #7127BA;
}

.sectionOne .informations .Ellipse {
    position: absolute;
    top: 100px;
    left: 102px;
    width: 152px;
    height: 60px;
}

.sectionOne .informations .informationFooter {
    font-size: 12px;
}

@media (max-width: 810px) {
    body, html {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
    }

    .sectionOne {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
    }

    .developer {
        margin-top: 100px;
    }

    .arrowDeveloper p {
        font-size: 1rem;
    }

    .ArrowDeveloperMobile img {
        display: none;
    }

    .arrowDeveloper {
        width: 100%;
        left: 0;
        display: block;
        text-align: center;
        top: 40px;
    }

    .sectionOne .informations {
        width: 100%;
        display: block;
        margin: -50px auto 100px;
        text-align: center;
    }

    .sectionOne .informations p {
        margin-bottom: 10px;
        font-size: 1.1rem;
    }

    .sectionOne .informations .informationH2 {
        width: 100%;
        padding: 0 20px;
        font-size: 1.9rem;
        line-height: 40px;
    }

    .sectionOne .informations img {
        display: none;
    }

    .sectionOne .informations .informationFooter {
        font-size: 0.90rem;
        top: 10px;
    }
}