/* Estilo principal do botão */
.button {
    position: fixed; /* Fixa o botão na posição da tela */
    bottom: 20px; /* Distância do fundo da página */
    right: 20px; /* Distância do lado direito da página */
    width: 50px; /* Largura do botão */
    height: 50px; /* Altura do botão */
    background-color: #6131a5; /* Cor de fundo (pode ajustar conforme necessário) */
    color: #ffffff; /* Cor do ícone */
    border: none; /* Remove borda */
    border-radius: 50%; /* Arredonda para fazer um círculo */
    display: flex; /* Centraliza o ícone no botão */
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
    cursor: pointer; /* Altera o cursor para mãozinha */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Adiciona uma sombra */
    opacity: 0; /* Inicialmente invisível */
    visibility: hidden; /* Inicialmente escondido */
}

/* Estilo do botão quando visível */
.visible {
    opacity: 1; /* Totalmente visível */
    visibility: visible; /* Agora está visível */
}

/* Estilo do ícone (opcional) */
.icon {
    font-size: 20px; /* Tamanho do ícone */
}
