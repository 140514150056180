.card {
    width: 49%;
    background: rgb(2,0,36);
    background: linear-gradient(140deg, rgba(19, 4, 40, 1) 7%, rgba(37, 16, 67, 1) 34%, rgba(56, 18, 109, 1) 57%, rgba(38, 16, 69, 1) 85%, rgba(25, 6, 52, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020024",endColorstr="#2c1250",GradientType=1);
    height: 196px;
    border-radius: 15px;
    border-top: 5px solid #4F228D;

    display: flex;
    justify-content: center;
    align-items: center;
}

.card img {
    padding-left: 50px;
    padding-right: 50px;
}

.containerCardRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 10px;
    gap: 5px;
}

.containerCardRight h3 {
    font-size: 24px;
}

.containerCardRight p {
    font-size: 12px;
    display: -webkit-box;        /* Define um contêiner flexível em bloco */
    -webkit-box-orient: vertical; /* Define a orientação do layout */
    line-clamp: 3;
    -webkit-line-clamp: 3;       /* Limita o texto a 3 linhas */
    overflow: hidden;            /* Esconde o texto extra */
    text-overflow: ellipsis;
}

.containerCardRight button {
    margin-top: 8px;
    width: 130px;
    background-color: #2C1250;
    border: 1px solid #693B93;
    border-radius: 8px;
    padding: 10px 0;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.containerCardRight button:hover {
    background-color: #3E1B6A;
    transform: translateY(-2px);
}

.containerCardRight button:active {
    transform: translateY(0);
    background-color: #281044;
}

@media (max-width: 1024px) {
    .card img {
        width: 130px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .containerCardRight h3 {
        font-size: 1.2rem;
    }

    .containerCardRight p {
        font-size: 0.7rem;
    }

    .containerCardRight button {
        width: 120px;
        padding: 12px 0;
    }
}

@media (max-width: 670px) {
    .card {
        width: 100%;
    }

    .containerCardRight p {
        line-clamp: 2;
        -webkit-line-clamp: 2;
    }
}