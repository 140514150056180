.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1A0B2E;
    height: 113px;
}

.headernav {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    width: 1300px;    
}
