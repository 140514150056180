.sectionThree {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 1280px;
    width: 100%;
    max-height: 700px;
    height: 100%;
    background: url('/src/assets/images/bg-experience.png') no-repeat center center;
    background-size: contain;
    padding: 0 15px;
}

.titleSectionThree h2 {
    font-size: 40px;
    margin-top: 100px;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 68px;
    gap: 10px;
}

@media (max-width: 1024px) {
    .sectionThree {
        padding: 0 15px;
    }

    .titleSectionThree h2 {
        font-size: 2rem;
    }

    .cards {
        margin-bottom: 40px;
    }
}

@media (max-width: 670px) {
    .sectionThree {
        padding: 0 15px;
        max-height: 1000px;
        align-items: center;
    }

    .titleSectionThree h2 {
        margin-top: 15px;
        font-size: 1.8rem;
    }

    .cards {
        gap: 12px;
        margin-top: 30px;
        margin-bottom: 15px;
    }
}
