.section {
    margin-top: 15px;
}

.section p {
    line-height: 25px;
    padding: 15px 15px;
}

.backgroundText {
    border-radius: 10px;
    background: rgb(77,31,128);
    background: rgb(77,31,128);
    background: -moz-linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    background: -webkit-linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    background: linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d1f80",endColorstr="#11071f",GradientType=1);
}

.section .topic {
    color: #7127ba;
    font-size: 20px;
    font-weight: 700;
    margin-top: 20px;
    padding-left: 0px;
}