.inputFile {
    margin-top: 15px;
}

.buttonWepb {
    border-radius: 5px;
    font-size: 18px;
    background-color: blueviolet;
    padding: 20px;
    margin-top: 15px;
}