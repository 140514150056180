.sectionFive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
}

.sectionFive .projectLeft {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    background: url('/src/assets/images/bg-experience.png') no-repeat right;
    background-size: contain;
}

.sectionFive .projectLeft .sideLeft {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.sectionFive .projectLeft .sideLeft h3 {
    color: #9857D3;
    font-size: 16px;
    font-weight: 600;
    margin-top: -180px;
}

.sectionFive .projectLeft .sideLeft h2 {
    color: #CCD6F6;
    font-size: 34px;
    font-weight: 600;
}

.sectionFive .projectLeft .sideLeft .textProject {
    top: 100px;
    width: 112%;
    position: absolute;
    color: #CCD6F6;
    font-size: 15px;
    word-spacing: 2px;
    background: rgba(105, 59, 147, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 14px;
    padding: 30px 40px;
    margin-top: 220px;
}

.sectionFive .projectLeft .sideLeft .textProject p {
    display: -webkit-box;        
    -webkit-box-orient: vertical;
    line-clamp: 5;
    -webkit-line-clamp: 5;       
    overflow: hidden;            
    text-overflow: ellipsis;
}

.sectionFive .projectLeft .sideRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.sectionFive .projectLeft .sideRight img {
    margin: 150px 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

/* projectRight */
.sectionFive .projectRight {
    width: 100%;
    display: flex;
    justify-content: center;
    justify-items: center;
    background: url('/src/assets/images/bg-experience.png') no-repeat left;
    background-size: contain;
}

.sectionFive .projectRight .sideRight {
    width: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.sectionFive .projectRight .sideRight {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}

.sectionFive .projectRight .sideRight img {
    margin: 150px 0;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.sectionFive .projectRight .sideRight h3 {
    color: #9857D3;
    font-size: 16px;
    font-weight: 600;
    margin-top: -180px;
    text-align: right;
}

.sectionFive .projectRight .sideRight h2 {
    color: #CCD6F6;
    font-size: 34px;
    font-weight: 600;
    text-align: right;
}

.sectionFive .projectRight .sideRight .textProject {
    top: 100px;
    right: 0;
    width: 112%;
    position: absolute;
    color: #CCD6F6;
    font-size: 15px;
    word-spacing: 2px;
    background: rgba(105, 59, 147, 0.2);
    backdrop-filter: blur(40px);
    border-radius: 14px;
    padding: 30px 40px;
    margin-top: 220px;
}

.sectionFive .projectRight .sideRight .textProject p {
    display: -webkit-box;        
    -webkit-box-orient: vertical;
    line-clamp: 5;
    -webkit-line-clamp: 5;       
    overflow: hidden;            
    text-overflow: ellipsis;
}

@media (max-width: 1024px) {
    .sectionFive .projectLeft .sideLeft .textProject,
    .sectionFive .projectRight .sideRight .textProject {
        margin-top: 180px;
    }

    .sectionFive .projectLeft .sideLeft .textProject p,
    .sectionFive .projectRight .sideRight .textProject p {
        line-clamp: 4;
        -webkit-line-clamp: 4;
    }
}

@media (max-width: 810px) {
    .sectionFive {
        background-position: center;
        flex-direction: column;
        margin-top: 50px;
    }

    .sectionFive .projectLeft,
    .sectionFive .projectRight {
        width: 100%;
        display: flex;
        justify-content: center;
        justify-items: center;
        flex-direction: column;
    }

    .sectionFive .projectRight {
        flex-direction: column-reverse;
    }

    .sectionFive .projectLeft .sideLeft,
    .sectionFive .projectRight .sideRight {
        width: 100%;
    }

    .sectionFive .projectLeft .sideLeft h3,
    .sectionFive .projectLeft .sideLeft h2,
    .sectionFive .projectRight .sideRight h3,
    .sectionFive .projectRight .sideRight h2 {
        margin: 0;
        width: 100%;
        text-align: center;
    }

    .sectionFive .projectLeft .sideLeft h2,
    .sectionFive .projectRight .sideRight h2 {
        margin-bottom: 30px;
    }

    .sectionFive .projectLeft .sideLeft .textProject,
    .sectionFive .projectRight .sideRight .textProject {
        width: 100%;
        position: static;
        margin: 0;
    }

    .sectionFive .projectLeft .sideLeft .textProject p,
    .sectionFive .projectRight .sideRight .textProject p {
        line-clamp: 5;
        -webkit-line-clamp: 5;
    }

    .sectionFive .projectLeft .sideRight,
    .sectionFive .projectRight .sideLeft {
        width: 100%;
    }

    .sectionFive .projectLeft .sideRight img,
    .sectionFive .projectRight .sideRight img {
        margin: 20px 0 50px;
        border-radius: 15px;
    }

    .sectionFive .projectRight .sideRight img {
        margin: 20px 0px 50px;
    }
}