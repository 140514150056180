.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: column;
    background-color: #11071f;
}

.footer .footerContainer {
    width: 100%;
    max-width: 1280px;
    padding: 0 15px;
}

.footer .footerContainer h2 {
    margin-top: 20px;
    font-size: 25px;
}

.footer .footerContainer .socialMedia {
    display: flex;
    gap: 10px;
    font-size: 25px;
    padding: 15px 0;
}

.footer .footerContainer p {
    padding-bottom: 15px;
    font-size: 15px;
}

@media (max-width: 1280px) {
    .footer .footerContainer {
        padding: 0;
    }
}
