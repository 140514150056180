.menuResponsive {
    display: none;
}

@media (max-width: 810px) {
    .menuResponsive {
        display: flex;
        align-items: center;
        z-index: 50;
    }

    .iconActive, .icon {
        position: absolute;
        right: 20px;
    }

    .menuResponsiveList {
        position: relative;
        right: 0px;
        top: 138px;
        flex-direction: column;
        flex-wrap: nowrap;
        color: white;
        font-size: 1.2rem;
        font-weight: 600;
        background-color: #1A0B2E;
        border-bottom-left-radius: 10px;
        width: 250px;
        opacity: 0;
        transform: translateX(100%);
        transition: opacity 0.3s ease, transform 0.3s ease;
        pointer-events: none;
    }

    .menuResponsiveListOpen {
        opacity: 1;
        transform: translateX(0);
        pointer-events: auto;
    }

    .menuResponsiveListItens {
        padding: 0;
        list-style-type: none;
        width: 100%;
    }

    .menuResponsiveListItens li {
        cursor: pointer;
        padding: 15px 0 15px 20px;
        border-bottom: 1px solid #3c2757;
    }

    .menuResponsiveListItens li:last-child {
        border: none;
        border-bottom-left-radius: 10px;
    }

    .menuResponsiveListItens li:hover {
        background-color: rgba(60, 4, 112, 0.5);
        padding: 15px 0 15px 20px;
        transition: ease 0.4s;
    }

    .menuResponsiveListItens li a {
        text-decoration: none;
    }
}

@media (max-width: 375px) {
    .menuResponsiveList {
        font-size: 1rem; 
        top: 120px; 
    }

    .iconActive, .icon {
        right: 10px; 
    }
}
