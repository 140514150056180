.logotipo {
    display: flex;
    align-items: flex-start;
    width: 50%;
    padding-left: 15px;
}

@media (max-width: 810px) {
    .logotipo {
        width: 100%;
    }
}