.menu {
    display: flex;
    width: 50%;
}

.menu > ul {
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 20px;
}

.menu > ul > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 10px 20px;
}

.menu > ul > li > a:hover {
    background-color: rgba(60, 4, 112, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
    transition: ease 0.4s;
}

@media (max-width: 810px) {
    .menu {
        display: none;
    }
}