.contentLeft,
.contentRight {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
    padding-bottom: 30px;
}

.contentLeft .left,
.contentRight .right {
    width: 50%;
    padding-right: 30px;
}
.contentRight .right {
    padding-right: 0px;
    padding-left: 30px;
}

.contentLeft .left h2,
.contentRight .right h2 {
    font-size: 25px;
    color: #9857D3;
    padding: 10px 0;
}
.contentRight .right h2 {
    text-align: right;
}

.contentLeft .left p,
.contentRight .right p {
    padding: 10px;
    line-height: 25px;
}

.backgroundText {
    border-radius: 10px;
    background: rgb(77,31,128);
    background: -moz-linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    background: -webkit-linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    background: linear-gradient(159deg, rgba(77,31,128,0.7259278711484594) 3%, rgba(44,18,80,0.7343312324929971) 25%, rgba(17,7,31,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4d1f80",endColorstr="#11071f",GradientType=1);
    margin-top: 20px;
}

.contentLeft .link,
.contentRight .link {
    text-decoration: none;
}

.contentLeft .link:hover,
.contentRight .link:hover {
    color: #9857D3;
}

.contentLeft .right,
.contentRight .left {
    width: 50%;
}

.contentLeft .right .containerProject,
.contentRight .left .containerProject {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    gap: 20px;
    background: url('/src/assets/images/bg-experience.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.contentLeft .right .containerProject .leftColumn,
.contentRight .left .containerProject .leftColumn {
    flex: 1;
    display: flex; 
    justify-content: center; 
    align-items: center; 
}

.contentLeft .right .containerProject .rightColumn,
.contentRight .left .containerProject .rightColumn {
    flex: 1; 
    display: flex;
    flex-direction: column; 
    align-items: center;
    gap: 20px;
}


.contentLeft img,
.contentRight img {
    width: 100%; 
    max-width: 300px; 
    height: auto; 
} 

.contentLeft img:hover,
.contentRight img:hover {
    transform: scale(1.05); 
    transition: transform 0.3s ease; 
}

@media (max-width: 810px) {
    .contentLeft,
    .contentRight {
        flex-direction: column;
        gap: 40px;
    }

    .contentRight {
        flex-direction: column-reverse;
    }

    .contentLeft .left,
    .contentRight .right {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        font-size: 0.90rem;
    }

    .contentLeft .right,
    .contentRight .left {
        width: 100%;
    }
}