.sectionTwo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 1280px;
    width: 100%;
    gap: 35px;
}

.sectionTwo h2 {
    font-size: 50px;
    padding: 0 15px;
    line-height: 60px;
}

.sectionTwo p {
    font-size: 21px;
    margin-top: 0px;
    max-width: 892px;
    position: relative;
    padding: 0 15px;
}

.company {
    color: #7127ba;
    padding-left: 30px;
    font-weight: bold;
}

.sectionTwo a {
    text-decoration: none;
}

.logoAscensus {
    position: absolute;
    top: 5px
}

@media (max-width: 810px) {
    .sectionTwo h2 {
        display: block;
        text-align: center;
        font-size: 2rem;
        width: 100%;
        line-height: 40px;
    }

    .sectionTwo p {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 1.1rem;
    }

    .logoAscensus {
        top: 2px
    }
}

@media (max-width: 480px) {
    .sectionTwo {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 40px;
    }

    .mainCall {
        font-size: 1.77rem;
        padding: 0 10px;
        line-height: 35px;
        margin-bottom: 10px;
    }

    .sectionTwo p {
        width: 100%;
        padding: 0 10px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        line-height: 25px;
    }

    .company {
        padding-left: 0px;
    }

    .logoAscensus {
        display: none;
    }
}
